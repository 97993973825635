import React, { useContext } from "react";
import { Link } from "gatsby";
import { Row, Col, Container } from "react-bootstrap";
import PageWrapper from "../../components/PageWrapper";
import SEO from "../../components/Layout/SEO";
import SuccessStories from "../../sections/camelo/SuccessStories";

import bgSection from "../../assets/image/customers/the_big_event_photography_customer_story_thumbnail_feature_image.png";

const MiddleCTA = () => {
  return (
    <Row className="bg-default-1 border-top">
      <Container>
        <Col xs="12" className="text-center pb-24">
          <h2 className="gr-text-5 pt-24 pb-8">
            <span
              className="highlighted1"
              style={{ backgroundPosition: "0 20px" }}
            >
              Try Camelo now and see the difference yourself.
            </span>
          </h2>
          <div>
            <Link
              onClick={() => {
                if (typeof window.gtag_report_conversion === "function") {
                  window.gtag_report_conversion(process.env.SIGNUP_URL);
                }
              }}
              to={process.env.SIGNUP_URL}
              target="__blank"
              className="btn btn-primary with-icon gr-hover-y"
            >
              Start scheduling free
              <i className="icon icon-tail-right font-weight-bold"></i>
            </Link>
            <p className="gr-text-12 pt-8">
              59 companies signed up in the last week alone!
            </p>
          </div>
        </Col>
      </Container>
    </Row>
  );
};

const TheBigEventPhotoGraphyStory = () => {
  return (
    <>
      <PageWrapper>
        <SEO title="How The Big Event transformed their employee management with Camelo"></SEO>
        <div className="inner-banner bg-default-6 pt-24 pt-lg-30">
          <Container>
            <Row className="justify-content-center pt-5">
              <Col xl="8" lg="9">
                <div className="px-md-12 mb-13 text-center">
                  <h1 className="title gr-text-4 mb-8 mb-lg-12">
                    How The Big Event transformed their employee management with
                    Camelo
                  </h1>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div
          className="video-section py-15 py-sm-20 py-lg-35 dark-mode-texts "
          css={`
            background-image: url(${bgSection});
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
          `}
        />

        <div className="pb-2 pb-lg-9 pt-10 pt-lg-15 bg-default-6 customer-story">
          <Container>
            <Row className="justify-content-center">
              <Col xl="10" lg="10">
                <div className="px-md-12 text-black mb-13 mb-lg-22">
                  <p className="gr-text-8 text-black">
                    Located in Tennessee,
                    <Link to="https://www.thebigevent.us/" target="__blank">
                      {` The Big Event `}
                    </Link>
                    is a family owned and operated photography studio
                    specializing in kids' photography, weddings, corporate
                    events, and more. With over 15 years of experience in the
                    field, the studio strives to provide customers with images
                    that not only tell their story but also become a part of
                    their family history, cherished for generations to come.
                  </p>
                  <p className="gr-text-8 text-black">
                    The Big Event struggled most with employee management like
                    hiring, scheduling, and payroll processing. While many
                    solutions existed, they were too costly for their small
                    business budget. That's when they stumbled upon Camelo,
                    which turned out to be exactly what they needed—a simple,
                    affordable way to keep their business running smoothly.
                  </p>

                  <h4 className="pt-8 pb-4">
                    The turning point for the commercial photographer
                  </h4>
                  <p className="gr-text-8 text-black">
                    John King—the owner of The Big Event—is a professional
                    photographer and started out shooting stock and doing all
                    commercial work. Part of this commercial work was taking
                    pictures of Santa for ad agencies and stock photography.
                  </p>

                  <p className="gr-text-8 text-black">
                    At the beginning, he never did any portrait work or children
                    photography. One day, one of the Santas asked if John could
                    take pictures of him with families and children at an event
                    he was doing. “I thought, why not?” he remembered. That was
                    normally a down time of the year for him so he agreed.
                  </p>

                  <blockquote className="blockquote mb-4 card-body">
                    <p className="gr-text-8 text-black">
                      ”Since that day, I have grown my Santa business to over
                      thirty five thousand children served per season.”
                    </p>
                  </blockquote>

                  <h4 className="pt-8 pb-4">
                    Scheduling became challenges for a small business
                  </h4>

                  <p className="gr-text-8 text-black">
                    For scheduling, The Big Event used pen and paper since these
                    were the fastest and most available tools. However, problems
                    started rising over time. Scheduling process became slow and
                    took time, especially in busy seasons. Mistakes—like
                    forgetting to update someone’s shifts or miscalculating work
                    hours—kept happening.
                  </p>

                  <p className="gr-text-8 text-black">
                    “Communicating with employees was difficult,” John recalled.
                    He also shares that he realized hiring, scheduling, and
                    payroll are always the hardest.
                  </p>

                  <blockquote className="blockquote mb-4 card-body">
                    <p className="gr-text-8 text-black">
                      ”There are plenty of things to help with that, but for
                      most small businesses, they are just too expensive and not
                      a reality.”
                    </p>
                  </blockquote>

                  <h4 className="pt-8 pb-4">Finding Camelo: the right fit</h4>
                  <p className="gr-text-8 text-black">
                    After facing so many challenges in the management process,
                    John decided to be more resourceful and seek different ways
                    to efficiently get the same job done. “Yours (Camelo)
                    and a couple of other apps have changed everything for me.” John said.
                  </p>

                  <p className="gr-text-8 text-black">
                    One day, John found Camelo and immediately got
                    interested.
                  </p>
                  <blockquote className="blockquote mb-4 card-body">
                    <p className="gr-text-8 text-black">
                      ”I was immediately hooked! It (Camelo) was so simple to
                      figure out and start using right away.”
                    </p>
                  </blockquote>

                  <p className="gr-text-8 text-black">
                    John also shared that The Big Event’s employees took it
                    naturally. Everything has been such a smooth and pleasant
                    experience for them.
                  </p>

                  <h4 className="pt-8 pb-4">
                    Efficiency boost with digital scheduling on Camelo
                  </h4>
                  <p className="gr-text-8 text-black">
                    Before Camelo, John would always print a paper calendar or
                    use a whiteboard to fill in names and times. Now he just
                    breezes through the process with the copy and paste.
                  </p>

                  <blockquote className="blockquote mb-4 card-body">
                    <p className="gr-text-8 text-black">
                      “It is so quick and easy to see everyone's schedule and no
                      more forgetting the days they requested off.”
                    </p>
                  </blockquote>

                  <p className="gr-text-8 text-black">
                    “Having them put their
                    <Link
                      to="https://camelohq.com/features/scheduling-software"
                      target="__blank"
                    >
                      {` availability `}
                    </Link>
                    in is extremely helpful,” he said.
                  </p>

                  <h4 className="pt-8 pb-4">
                    The features that make a difference
                  </h4>
                  <p className="gr-text-8 text-black">
                    Of all the Camelo features that John loves, he finds
                    <Link
                      to="https://camelohq.com/features/time-and-attendance/"
                      target="__blank"
                    >
                      {` clocking in with camera `}
                    </Link>
                    is the most helpful one. “It also helps tremendously with
                    the ability to clock in with a selfie. No more cheating
                    (laugh),” he shared.
                  </p>
                  <p className="gr-text-8 text-black">
                    With Camelo, now the owner of The Big Event can save time
                    for what he finds most important. “Camelo saves me countless
                    hours during the year so I can focus on work instead of
                    scheduling,” John explained. “I couldn't ask for a better
                    app to handle our scheduling,” he added.
                  </p>
                  <p className="gr-text-8 text-black">
                    When asked if he would recommend Camelo to others, he
                    excitedly agreed.
                  </p>

                  <blockquote className="blockquote card-body">
                    <p className="text-black pt-4 gr-text-6 lead">
                      “Absolutely recommend. The ease of use and time savings
                      alone is worth it.”
                    </p>
                  </blockquote>

                  <h4 className="pt-8 pb-4">Moving forward together</h4>
                  <p className="gr-text-8 text-black">
                    About the experience with using Camelo, the owner doesn’t
                    hide how content he is.
                  </p>
                  <blockquote className="blockquote card-body">
                    <p className="text-black pt-4 gr-text-6 lead">
                      “I can't say enough good things about the Camelo team. Not
                      only have they put together an incredible app, but they
                      are genuinely helpful and so easy to work with.”
                    </p>
                  </blockquote>
                  <p className="gr-text-8 text-black">
                    “Even when the problem was me,” he added. “The only issues
                    I've had (which were my own mistakes) were fixed and handled
                    by your team very quickly and professionally,” John
                    continued.
                  </p>
                  <p className="gr-text-8 text-black">
                    As The Big Event’s growth journey continues, they decide to
                    keep using the app. “I am loving all the features and
                    getting to know the app better,” John excitedly said. With
                    Camelo supporting their journey, The Big Event has
                    transformed scheduling from a daily challenge into a
                    background task, and now they can fully focus on what they
                    do best: creating perfect photographs for customers.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <MiddleCTA />
        <SuccessStories />
      </PageWrapper>
    </>
  );
};
export default TheBigEventPhotoGraphyStory;
